body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

math-field::part(menu-toggle) {
  display: none;
}

math-field::part(virtual-keyboard-toggle) {
  color: black;
}

.feedback-carousel {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.carousel {
  display: flex;
  gap: 24px;
  animation: scrollLeft 45s linear infinite;
  width: max-content; /* Allows infinite scrolling */
}

/* Pseudo-element to create a seamless infinite loop */
.carousel::after {
  content: "";
  display: block;
  min-width: 100%; /* Ensures a duplicate section of cards */
}

.carousel .card {
  min-width: 400px; /* Adjust card width */
  flex-shrink: 0;
}

@keyframes scrollLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-15%); /* Only move half to prevent gaps */
  }
}

@keyframes scrollRight {
  from {
    transform: translateX(-15%);
  }
  to {
    transform: translateX(0%);
  }
}

/* Left-moving carousel */
.scroll-left .carousel {
  animation: scrollLeft 45s linear infinite;
}

/* Right-moving carousel */
.scroll-right .carousel {
  animation: scrollRight 45s linear infinite;
}
